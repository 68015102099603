<template>
    <div class="order-deliver-remark">
        <div class="common-title">{{ $t('转交付备注') }}</div>
        <van-form ref="form" input-align="right" error-message-align="right" >
            <van-field
                v-model.trim="escrowRemark"
                rows="3"
                autosize
                type="textarea"
                maxlength="200"
                :placeholder="$t('请输入')"
                show-word-limit
                required
                :rules="[{required:true}]"
            />
        </van-form>
        <div class="common-footer">
            <van-button class="btn" @click="handleCancel">{{ $t('取消') }}</van-button>
            <van-button class="btn" type="primary" @click="handleSave">{{ $t('保存') }}</van-button>
        </div>
    </div>
</template>
<script>
import orderServices from '@/services/orderServices.js'
export default {
  data(){
    return {
      escrowRemark:'',
      orderId: ''
    }
  },
  mounted(){
    const { id,escrowRemark }=this.$route.query
    this.orderId = id
    this.escrowRemark = escrowRemark
  },
  methods:{
    handleCancel(){
      this.$router.go(-1)
    },
    async handleSave(){
      try {
        await this.$refs.form.validate()
        const params = {
          id: this.orderId,
          escrowRemark: this.escrowRemark
        }
        await orderServices.escrowRemark(params)
        this.handleCancel()
      } catch (error) {
        
      }
    }
  }
}
</script>
<style lang="less" scoped>
.order-deliver-remark{
    padding: 10px 16px !important;
}
</style>